import React from "react"
import { Link } from "gatsby"
import Masonry from "react-masonry-css"
import Image from "gatsby-plugin-sanity-image"
import { useStaticQuery, graphql } from "gatsby"
import ScrollingText from "../../components/ScrollingText"
import { Seo } from "../../components/seo"
import { Box } from "@mui/material"
import { Layout } from "../../components/layout"
import slugify from "@sindresorhus/slugify"

const Archive = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityArchivePage {
        title
        posts {
          title
          mainImage {
            ...Image
          }
          slug {
            current
          }
        }
      }
    }
  `)

  const title = data.sanityArchivePage.title
  const images = data.sanityArchivePage.posts.map(
    ({ mainImage, slug, title }) => {
      return { image: mainImage, slug: slug.current, title }
    }
  )

  const breakpointColumnsObj = {
    default: 3,
    1200: 2,
    500: 1,
  }

  return (
    <Layout>
      <ScrollingText title={title} />
      <Seo title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div
        className="d-flex align-items-center flex-column"
        style={{ marginTop: 100 }}
      ></div>
      <Box display="flex" justifyContent="center">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {images.map((item) => (
            <div className="archive-image-wrapper" key={item.slug}>
              <Link to={`/article/${slugify(item.slug)}/`} key={item.slug}>
                <Image
                  {...item.image}
                  alt={item.title}
                  width={600}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "auto",
                  }}
                />

                <div className="archive-image-title">{item.title}</div>
              </Link>
            </div>
          ))}
        </Masonry>
      </Box>
    </Layout>
  )
}

export default Archive
