import React, { useRef, useState, useLayoutEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import useDimensions from 'react-cool-dimensions'
let animationReq
let update = true

const ScrollingText = ({ title }) => {
  const ref = useRef()
  const ref2 = useRef()
  const [posX, setPosX] = useState(0)
  const [bWidth] = useWindowSize()

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...

      unobserve() // To stop observing the current target element
      observe() // To re-start observing the current target element
    },
  })

  const scrollRight = () => {
    if (!animationReq || update) {
      update = true

      animate({
        move: 2,
        start: posX,
        getCurrentPos: progress => setPosX(progress),
        stop: width,
      })
    }
  }
  useLayoutEffect(() => {
    ref.current.innerHTML = ReactDOMServer.renderToStaticMarkup(
      whitespaceToNbsp(title)
    ) // Reset the innerHTML on screen wdith change to recalculate

    while (ref.current.scrollWidth < bWidth) {
      ref.current.innerHTML =
        ref.current.innerHTML + ' ' + ref.current.innerHTML + ' '
    }
    ref2.current.innerHTML = ref.current.innerHTML
  }, [ref, bWidth])

  useLayoutEffect(() => {
    if (width) {
      scrollRight()
    }
    return () => {
      cancelAnimationFrame(animationReq)
    }
  }, [width, bWidth])

  // useEffect(() => {
  //   const cleanup = () => {
  //     update = false
  //     cancelAnimationFrame(animationReq)
  //   }

  //   window.addEventListener('beforeunload', cleanup)

  //   return () => {
  //     window.removeEventListener('beforeunload', cleanup)
  //   }
  // }, [])
  return (
    <div
      className="rolling-hover"
      onMouseOut={() => {
        update = true
        scrollRight()
      }}
      onBlur={() => {
        update = true
        scrollRight()
      }}
      onMouseOver={() => {
        update = false
      }}
      onFocus={() => {
        update = false
      }}
    >
      <div style={{ left: posX, position: 'absolute' }}>
        <span
          className="archive-rolling archive_title"
          ref={el => {
            observe(el) // Set the target element for measuring
            ref.current = el // Share the element for other purposes
          }}
        >
          {whitespaceToNbsp(title)}
        </span>
      </div>
      <div style={{ left: posX - width, position: 'absolute' }}>
        <span className="archive-rolling archive_title" ref={ref2}></span>
      </div>
    </div>
  )
}

export default ScrollingText

const animate = ({ getCurrentPos, move, stop, start }) => {
  let progress = start
  const animateLoop = () => {
    getCurrentPos(progress)
    progress = progress + move
    if (progress < stop && update) {
      requestAnimationFrame(animateLoop)
    } else if (update) {
      progress = 0
      requestAnimationFrame(animateLoop)
    }
  }
  animationReq = requestAnimationFrame(animateLoop)
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

const whitespaceToNbsp = str => {
  return (
    <>
      {str.split(/(\s+)/).map((item, i) => {
        if (item[0] === ' ') {
          return item
            .split('')
            .map((c, index) => <span key={index}>&nbsp;</span>)
        }
        return <span key={'key' + i}>{item}</span>
      })}
    </>
  )
}
